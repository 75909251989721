<template>
  <div class="pieMain"></div>
</template>

<script>
export default {
  name: '',
  props: {
    data: Object
  },
  data() {
    return {
      dataSource: this.data,
      myChart: undefined
    }
  },
  watch: {
    data: {
      deep: true, // 深度监听
      handler: function (newVal) {
        this.dataSource = newVal
        const option = this.myChart.getOption()
        option.series[0].data = this.dataSource.data1
        this.myChart.setOption(option)
      }
    }
  },
  methods: {
    // 交易支出 饼图
    setChart() {
      let option = {
        title: {
          text: '【' + this.dataSource.title + '】',
          x: 'center',
          top: -5,
          textStyle: {
            color: '#75deef',
            fontSize: 12,
            fontWeight: 'normal'
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)',
          backgroundColor: '#11367a',
          textStyle: {
            color: '#6dd0e3',
            fontSize: 10
          }
        },
        series: [
          /*{
                        type: 'pie',
                        startAngle: 160,
                        radius: [0, '30%'],
                        label: {
                            show: true,
                            position: 'inside',
                            fontSize: 10,
                        },
                        center: ['50%', '60%'],
                        data: this.data.data,
                    },*/
          {
            type: 'pie',
            startAngle: 160,
            radius: ['52%', '72%'],
            labelLine: {
              lineStyle: {
                color: '#444b62'
              },
              length: 5,
              length2: 5
            },
            emphasis: {
              label: {
                color: '#fff',
                show: true
              }
            },
            label: {
              position: 'outside',
              borderRadius: 4,
              color: '#fff',
              fontSize: 12,
              padding: 0,
              backgroundColor: '#183566',
              formatter: '{b}占比{d}%'
            },
            center: ['50%', '50%'],
            data: this.dataSource.data1,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      this.myChart = this.$echarts(this.$el)

      this.myChart.clear()
      this.myChart.resize()

      this.myChart.setOption(option)
      let obj = {
        type: 'highlight',
        name: []
      }
      /*     for (let i = 0; i < this.data.data.length - 1; i++) {
                obj.name.push(this.data.data[i].name)
            }*/
      this.myChart.dispatchAction(obj)
      this.myChart.on('mouseout', function () {
        this.myChart.dispatchAction(obj)
      })
    }
  },
  mounted() {
    this.setChart()
  }
}
</script>

<style lang="less" scoped>
.pieMain {
  width: 100%;
  height: 100%;
}
</style>
